@import "~bootstrap/scss/bootstrap";

$dark-background-color: #202124;
$dark-input-background-color: $gray-900;
$dark-text-color: #e8eaed;
$dark-accent-color: $gray-700;
$dark-light-accent-color: $gray-900;
$dark-border-color: #5f6368;
$dark-table-color: rgba($dark-border-color, .25);

$dark-edit-color: $warning;

body {

    margin: 0 !important;
    padding: 0 !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-family: 'Montserrat', sans-serif !important;

    .phrase.inactive {
        text-decoration: line-through;
        color: grey;
    }

    .mutedHelpText {
        font-size: .75rem;
    }

    .form-switch {

        .form-check-label {
            margin-left: .5rem;
        }
    }

    .row.header {

        .headerButton {
            margin-right: .25rem;
        }
    }

    .row.content {

        .table.conjugation {

            td {
                padding: 0 .25rem;
            }
        }

        .itemContainer {

            .card-body {
                padding: .7rem;

                .contentObjectTitle {
                    font-weight: bold;
                    font-size: 1.25rem;
                }

                .contentObjectSubTitle {
                    font-weight: normal;
                    font-size: .9rem;
                }
            }

            .description {
                margin-top: .5rem;
            }
        }
    }
}